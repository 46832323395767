var defaultConf = require('./config-default');

var BO_URL = 'jasfgg2021-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://jasfgg2021.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'jasfgg-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'jasfgg-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/b4event/jasfgg/jasfgg2021/jasfgg2021-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/b4event/jasfgg/jasfgg2021/jasfgg-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-39',
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-se/mot-de-passe-oublie.htm")
  },
  projectId: '1056757301402',
  // FCM sender id
  appId: 'EF7A9-340CB',
  // pushwoosh appid
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  CODEPUSH: {
    FEATURE_ENABLED: false
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  }
});