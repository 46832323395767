var _require = require('./profiles'),
    DEFAULT_PROFILE = _require.DEFAULT_PROFILE;

var _require2 = require('../../src/pages/pagesKeys'),
    HOME_PAGE_KEY = _require2.HOME_PAGE_KEY,
    FAVORITES_PAGE_KEY = _require2.FAVORITES_PAGE_KEY,
    NOTES_PAGE_KEY = _require2.NOTES_PAGE_KEY,
    LIST_GROUPS_PAGE_KEY = _require2.LIST_GROUPS_PAGE_KEY,
    KLIPSO_LEADS_PAGE_KEY = _require2.KLIPSO_LEADS_PAGE_KEY,
    INBOX_PAGE_KEY = _require2.INBOX_PAGE_KEY,
    LOGIN_PAGE_KEY = _require2.LOGIN_PAGE_KEY,
    USER_DATA_PAGE_KEY = _require2.USER_DATA_PAGE_KEY,
    SEARCH_PAGE_KEY = _require2.SEARCH_PAGE_KEY,
    SEARCH_TAIGA_PAGE_KEY = _require2.SEARCH_TAIGA_PAGE_KEY;

import { isSessionValid } from 'src/core/login/LoginService';
import config from 'data/config/config';
import { getBindedActions } from 'src/store/bindedActions';
import { get as getLabels } from 'src/core/Lang';
import qrcodeReaderIcon from 'data/public/icons/qrcode-reader.svg';
/**
 * Toolbar configuration per profile
 */

export default function (profile, pageKey) {
  // Home does not have AppToolbar component
  if (pageKey === HOME_PAGE_KEY) {
    return {};
  }

  switch (profile) {
    // Same for every profile

    /* case PROFILE_VISITEUR:
    case PROFILE_EXPOSANT: */
    // case PROFILE_PRESSE:
    default:
      return {
        homeButtonInToolbar: true,
        favButtonInToolbar: pageKey !== FAVORITES_PAGE_KEY,
        searchButtonInToolbar: pageKey !== SEARCH_PAGE_KEY && pageKey !== SEARCH_TAIGA_PAGE_KEY,
        menuButtonInToolbar: true,
        // Used when some profiles have access to reduced version of the app:
        // (e.g http://eurosatory-dev.mobile-spot.com/?profile=webapp-exh)
        rootCategoryButtonInToolbar: false && (pageKey === LIST_PAGE || pageKey === LIST_GROUPS_PAGE_KEY)
      };
  }
}
/**
 * Manual of configuration of icon home Toolbar
 *
 * reference to use isFontAwesome Url : https://fontawesome.com/v4.7.0/examples/
 *
 * name : String
 * filePath: String ; path of Image(png,svg...)
 * alt: String ; alt of Image
 * isFontAwesome: String ; if empty Font Awesome is not activate else the name of icon
 * isFontProject: String ; if empty Font Project is not activate else the icon Content inside font project
 * style: Object ; style of the icon
 * className: String ; name of classes used for this icon
 * label : String ; title of icon
 * action: Function ; action triggered in onClick(event) of this icon
 * badge: number ; number of badge stick to this icon
 *
 * so exemple to use isFontAwesome :
 *   -Increase icon sizes relative to their container : [fa-lg, fa-2x, fa-3x, fa-4x, fa-5x]
 *   -Fixed Width Icons : fa-fw
 *   -Bordered & Pulled Icons : fa-border (fa-pull-right, fa-pull-left)
 *   -Rotated & Flipped : [normal, fa-rotate-90, fa-rotate-180, fa-rotate-270, fa-flip-horizontal, fa-flip-vertical]
 *   -Stacked Icons : [fa-stack, fa-stack-1x, fa-stack-2x, fa-inverse]
 *   -Font : fa // (basic) , fas (solid), fad (duotone), far (regular), fal (light)
 */

function getLoginIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-user',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-login-icon' + (isSessionValid() ? ' connected' : ''),
    label: getLabels().common.login,
    action: function action() {
      getBindedActions().navigate(isSessionValid() ? USER_DATA_PAGE_KEY : LOGIN_PAGE_KEY);
    },
    badge: null
  };
}

function getFavoritesIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'far fa-star',
    //'fa fa-star',
    isFontProject: '',
    style: {},
    className: 'ht-icon  ht-favorites-icon',
    label: getLabels().common.goToFavorites,
    action: function action() {
      getBindedActions().navigate(FAVORITES_PAGE_KEY);
    },
    badge: null
  };
}

function getNotesIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'far fa-sticky-note',
    isFontProject: '',
    style: {},
    className: 'ht-icon  ht-notes-icon',
    label: getLabels().common.goToNotes,
    action: function action() {
      getBindedActions().navigate(NOTES_PAGE_KEY);
    },
    badge: null
  };
}

function getInboxIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'fa fa-envelope',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-inbox-icon',
    label: getLabels().common.goToInbox,
    action: function action() {
      getBindedActions().navigate(INBOX_PAGE_KEY);
    },
    badge: null // global.isCordovaContext ? this.props.inboxCount : null,

  };
}

function getKlipsoLeadsIcon() {
  return {
    filePath: qrcodeReaderIcon,
    alt: '',
    isFontAwesome: '',
    isFontProject: '',
    style: {},
    className: 'ht-kl-icon',
    label: getLabels().common.KlipsoLeads,
    action: function action() {
      getBindedActions().navigate(KLIPSO_LEADS_PAGE_KEY);
    },
    badge: null
  };
}

function getKlipsoSSOIcon() {
  return {
    filePath: qrcodeReaderIcon,
    alt: '',
    isFontAwesome: '',
    isFontProject: '',
    style: {},
    className: 'ht-kl-icon',
    label: getLabels().common.KlipsoSSO,
    action: function action() {
      getBindedActions().startLoginKlipsoSSO();
    },
    badge: null
  };
}

function getContactScanIcon() {
  return {
    filePath: qrcodeReaderIcon,
    alt: '',
    isFontAwesome: '',
    isFontProject: '',
    style: {},
    className: 'ht-kl-icon',
    label: getLabels().common.contactScan,
    action: function action() {
      getBindedActions().scanContact();
    },
    badge: null
  };
}

function getSearchIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'far fa-search fa-flip-horizontal',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-search-icon',
    label: getLabels().common.goToSearch,
    action: function action() {
      getBindedActions().navigate(SEARCH_PAGE_KEY);
    },
    badge: null
  };
}

function getMenuIcon() {
  return {
    filePath: '',
    alt: '',
    isFontAwesome: 'far fa-bars',
    isFontProject: '',
    style: {},
    className: 'ht-icon ht-menu-icon',
    label: getLabels().common.openMenu,
    action: function action() {
      getBindedActions().openMenu(HOME_PAGE_KEY);
    },
    badge: null
  };
}
/**
 * Toolbar configuration for home
 */


export function homeToolbarIcon(profile) {
  switch (profile) {
    case DEFAULT_PROFILE:
      var array = []; // login Icon

      if (config.LOGIN && config.LOGIN.FEATURE_ENABLED) array.push(getLoginIcon()); // Notes Icon

      if (config.NOTES && config.NOTES.FEATURE_ENABLED) array.push(getNotesIcon()); // Favorite Icon

      array.push(getFavoritesIcon()); // Inbox Icon
      //array.push(getInboxIcon());
      // Klipso Leads Icon

      if (config.KLIPSO_LEADS && config.KLIPSO_LEADS.FEATURE_ENABLED) array.push(getKlipsoLeadsIcon()); // Klipso Leads SSO Icon

      if (config.SSO && config.SSO.FEATURE_ENABLED) array.push(getKlipsoSSOIcon()); // Contact Scan Icon

      if (config.CONTACTS && config.CONTACTS.FEATURE_ENABLED) array.push(getContactScanIcon()); // Search Icon

      array.push(getSearchIcon()); // Menu Icon

      array.push(getMenuIcon());
      return array;

    default:
      return [];
  }
}